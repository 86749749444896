<template>
  <ion-app>
    <!-- <transition name="slide-fade" mode="out-in"> -->
    <ion-router-outlet />
    <!-- </transition> -->
    <teleport to="body">
      <div class="z-50 absolute w-full top-10">
        <transition-group name="fade-down" appear tag="ul" class="relative">
          <li v-for="toast in toasts" :key="toast.id" class="w-full flex justify-center">
            <toast :text="toast.text" :type="toast.type" :timeout="toast.timeout" :id="toast.id" />
          </li>
        </transition-group>
      </div>
      <popup v-model:on="showInstallMessage" title="Add Tutoruu to Home Screen! 📱" :text="``"
        :action="{ label: 'OK!' }" image="https://img.icons8.com/ios-glyphs/30/000000/share-rounded.png" />
      <popup v-model:on="firstTimeTutor" title="Set your own schedule"
        :text="`Select your preferred times to meet to start accepting session requests!`" :action="{
          label: 'Go!',
          onclick: () => {
            this.$router.push('/schedule');
            firstTimeTutor = false;
          },
        }" />
    </teleport>
  </ion-app>
</template>
<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { mapState, mapActions } from "vuex";
import Toast from "./components/Toast.vue";
import Popup from "./components/Popup.vue";
import mixpanel from "mixpanel-browser";
import { readFromLocalStorage, saveToLocalStorage } from "./helpers";

export default defineComponent({
  name: "App",
  computed: {
    ...mapState(["toasts", "user", "user_tutor"]),
  },
  data() {
    return {
      showInstallMessage: false,
      firstTimeTutor: null,
      firstTime: false,
    };
  },
  setup() {
    const route = useRoute();
    return { route };
  },
  created() {
    mixpanel.track("App started", {
      user: this.user,
    });

  },
  components: {
    IonApp,
    IonRouterOutlet,
    Toast,
    Popup,
  },
  mounted() {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode() && !!this.user && !this.user.tutor && !localStorage.getItem("AddedToHomeScreen")) {
      this.showInstallMessage = true;
      localStorage.setItem("AddedToHomeScreen", true)
    }

    let timeout = setInterval(() => {
      if (!!this.$store.state.user && !!this.$store.state.user_tutor?.schedule) {
        let slots = 0;
        for (const day of this.$store.state.user_tutor.schedule)
          if (!!day.slots) slots += day.slots.length;
        if (slots === 0) this.firstTimeTutor = true;
        clearInterval(timeout);
      }
    }, 2000);
  },
});
</script>

<style>
html,
body,
p,
h1,
h2,
button,
h3,
h4,
div {
  font-family: "Quicksand", sans-serif;
}
</style>
