import { getParams, readFromLocalStorage, saveToLocalStorage } from "./helpers";

class Me3temed {
  #token;

  constructor(app, options = {}) {
    this.API_URL = "https://me3temed.samuraisoftware.house/";
    this.app = app;
    this.user = options.user;
  }

  /**
   * @description Set me3temed token
   * @param token: token
   * @void
   */
  setToken(token) {
    this.#token = token;
    saveToLocalStorage("ME3TEMED_TOKEN", token);
  }

  /**
   * @description Get me3temed token
   * @returns token
   */
  getToken() {
    return this.#token || readFromLocalStorage("ME3TEMED_TOKEN");
  }

  /**
   * @description Set me3temed user
   * @param user: user
   * @void
   */
  setUser(user = {}) {
    this.user = user;
    saveToLocalStorage("ME3TEMED_USER", user, true);
  }

  /**
   * @description Get me3temed user
   * @returns user
   */
  getUser() {
    return this.user || readFromLocalStorage("ME3TEMED_USER", true);
  }

  /**
   * @description Send a post request to me3temed's server
   * @param route: /path/to/endpoint
   * @param data: request body
   * @callback cb: executes after response success. Receives response data as a parameter
   */
  async post(route, data = {}) {
    const response = await fetch(`${this.API_URL + route}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.getToken(),
      },
      body: JSON.stringify(data),
    });
    const resData = await response.json();
    if (response.ok) return resData;
    else {
      console.error(response.status, resData.message);
      return {
        success: false,
        error: { code: response.status, message: resData.message },
      };
    }
  }

  /**
   * @description Generate a one-time password for a user and email it to them
   * @param email: user's email
   * @returns Data object with temporary token & sets token
   */
  async getOTP(email) {
    const data = await this.post("otp", { email });

    saveToLocalStorage("TEMP_TOKEN_OTP", data.token);
    return data;
  }

  /**
   * @description Login a user
   * @param strategy: Specify the login method used. Leave it blank to login using the token (if it exists)
   * @oneOf ["", "otp", "password", or "passwordless"]
   * @param creds: The user's sign in credentials
   * @creds { email, password, otp }
   * @returns Data object with "user" property
   */
  async login(strategy = "", creds = {}) {
    if (strategy === "otp" && !this.#token)
      this.#token = readFromLocalStorage("TEMP_TOKEN_OTP");

    const { user, token, error } = await this.post(`login/${strategy}`, creds);
    if (!user) return { error };
    else {
      this.setToken(token);
      this.setUser(user);
      return { success: true, user };
    }
  }

  /**
   * @description register a user
   * @param strategy: Leave it blank for default email and password sign up.
   * @oneOf ["", "passwordless", "social"]
   * @param userData: The user data used to create the account.
   * @userData { email, password, name, img, meta, origin, isEmailVerified }
   * @returns Data object with "user" property
   */
  async register(strategy = "", userData = {}) {
    const { user, token, error } = await this.post(`register/${strategy}`, {
      app: this.app,
      ...userData,
    });

    this.setUser(user);
    this.setToken(token);

    if (!user) return { error };
    else return { success: true, user };
  }

  /**
   * @description check if the current session is authenticated
   * @returns Data object with "user" property and "loggedIn" boolean
   */
  async getAuth() {
    const params = getParams();
    if (!params?.token) if (!!this.getUser()) return { loggedIn: true, user: this.getUser() };
    
    this.setToken(params.token);
    const { user, error } = await this.login();
    if (!user) return { loggedIn: false, error };
    else return { loggedIn: true, user };
  }

  /**
   * @description logs out a user
   */
  async logout() {
    await this.post("logout");
    this.setToken("expired");
    this.setUser({ loggedOut: true });
  }

  /**
   * @description Get a configurable pre-built UI
   * @param options: UI configuration
   * @option type
   * @option strategy
   * @option app_name
   * @option redirect_url
   * @returns iframe ui object
   */
  ui(options = {}) {
    const { type, strategy, app_name, redirect_url } = options;
    return {
      title: `${app_name} | ${type} | ${strategy} | Me3temed Auth`,
      src: `${this.API_URL}${type}/${strategy}?app_name=${app_name}&redirect_url=${redirect_url}`,
    };
  }
}

export default Me3temed;
