<template>
  <button
    type="button"
    class="
      text-md
      relative
      group
      font-bold
      rounded-full
      hover:ring-offset-4 hover:scale-105
      active:scale-95
      focus:scale-95
      ring-2 ring-transparent
      md:hover:ring-orange
      md:focus:ring-orange
      ring-offset-0
      flex
      items-center
      justify-center
      cursor-pointer
      transition
      duration-300
      ease-out
      outline-none
      appearance-none
    "
    :class="{
      'w-full': full,
      'max-w-sm': !full,
      'py-4 px-5 sm:text-lg': !small,
      'py-2 px-3 sm:px-5 sm:py-3': small,
      'px-4': icon,
      'bg-orange hover:md:bg-white': type == 'primary',
      'hover:md:bg-orange bg-white': type == 'secondary',
      'hover:md:bg-orange-tint': type == 'ghost',
      'active:ring-offset-2': type != 'ghost',
      'bg-gray-300': type == 'disabled',
    }"
    @click.stop="clicked"
  >
    <img v-if="icon" :src="icon" class="w-6 h-6 mr-1" />
    <p
      class="text-md font-bold capitalize"
      :class="{
        'text-white': type == 'primary' || type == 'disabled',
        'group-hover:md:text-orange': type == 'primary',
        'text-orange': type == 'ghost' || type == 'secondary',
        'group-hover:md:text-white': type == 'secondary',
        'opacity-0': loading,
      }"
    >
      {{ text }}
    </p>
    <!-- <div
      v-if="loading"
      class="absolute inset-0 flex items-center justify-center"
    >
      <div
        class="
          border-white
          animate-spin
          inline-block
          w-8
          h-8
          border-l-4 border-r-4
          rounded-full
        "
        :class="{
          'border-orange': type == 'ghost' || type == 'secondary',
          'border-white': type == 'primary',
        }"
        role="status"
      >
        <span class="hidden">Loading...</span>
      </div>
    </div> -->
  </button>
</template>

<script>
export default {
  props: {
    text: String,
    type: String,
    icon: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  emits: ["click"],
  methods: {
    clicked() {
      if (!this.disabled) {
      //   this.loading = true;
        this.$emit("click");
      }
    },
    ionViewWillEnter() {
      this.loading = false
    },
    ionViewWillLeave() {
      this.loading = false
    },
  },
};
</script>
