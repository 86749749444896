//  reusable json presets
import Me3temed from "./me3temed";
export const me3temed = new Me3temed();

// APIs
import { api } from "./helpers";
const tutoruu_api = new api("https://api.tutoruu.com/api/", {
  contentType: "application/json; charset=UTF-8",
});

tutoruu_api.getStudentTutors = async (id) => {
  return await tutoruu_api.get(`user/${id}/tutors`)
}

export const tutoruuAPI = tutoruu_api

export const universityAPI = new api("http://universities.hipolabs.com/");

export const preferences = [
  {
    name: "Get Support",
    icon: "GetSupport",
    action: "Get the help you need now!",
  },
  {
    name: "Give Support",
    icon: "GiveSupport",
    action: "turn your GPA into $$$",
  },
  {
    name: "Both",
    icon: "GiveSupport",
    action: "ace your classes & earn money",
  },
];

export const universities = [
  {
    name: "American University in Cairo",
    abbrev: "AUC",
    index: 0,
    id: "61696641482a30bd076bc136",
  },
  {
    name: "New York University in Abu Dhabi",
    abbrev: "NYU AD",
    index: 1,
  },
  {
    name: "German University in Cairo",
    abbrev: "GUC",
    index: 2,
  },
  {
    name: "Misr International University",
    abbrev: "MIU",
    index: 3,
  },
];

