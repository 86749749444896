export const ruuChat = {
    namespaced: true,
    state: () => ({
        dialogue: null,
    }),
    mutations: {
        createDialogue(state, dialogue) {
            state.dialogue = dialogue;
        },
    },
    actions: {
        async send({ state }, message) {
            return await state.dialogue.send(message);
        }
    }
};
