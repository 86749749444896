import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAIR7GVtPXafyMKjJnRU1T0xehp2tRDbdY",
  authDomain: "tutoruu-9631a.firebaseapp.com",
  projectId: "tutoruu-9631a",
  storageBucket: "tutoruu-9631a.appspot.com",
  messagingSenderId: "329595816510",
  appId: "1:329595816510:web:47c4c621d6397ac785df6f",
  measurementId: "G-KZCT5RGDNF",
};

export const fbapp = initializeApp(firebaseConfig);
export const db = getFirestore(fbapp);

import { getDoc, doc } from "firebase/firestore";

export const getChat = async (id, id2) => {
  let chatId = `${id}:${id2}:`;
  let chatRef = doc(db, "chats", chatId);
  let chatSnap = (await getDoc(chatRef)).data();
  if (!chatSnap) {
    chatId = `${id2}:${id}:`;
    chatRef = doc(db, "chats", chatId);
    chatSnap = (await getDoc(chatRef)).data();
  }
  return { chatSnap, chatId };
};
