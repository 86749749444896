import store from "../../../store";

export const follow = async (id) => {
  return await store.state.feedAPI.post('Follower/Add', { followee_id:id });
}
export const unfollow = async (id) => {
  return await store.state.feedAPI.post('Follower/Remove', { followee_id:id });
}

// export const getFeedUser = async (id) => {
//   return await store.state.feedAPI.get(`user/${id}`);
// } 

export const searchUsers = async (query) => {
  return await store.state.feedAPI.post("Profile/Serach", { username: query });
} 

export const getFollowers = async (id) => {
  return await store.state.feedAPI.get(`Followers/${id}`);
}
export const getFollowings = async (id) => {
  return await store.state.feedAPI.get(`Following/${id}`);
}

export const getFollowersCount = async () => {
  return await store.state.feedAPI.get('Counters/Followers')
}

export const getFollowingCount = async () => {
  return await store.state.feedAPI.get('Counters/Following')
}

export const getProfile = async () => {
  return await store.state.feedAPI.get('Profile')
}

export const updateProfile = async (data) => {
  return await store.state.feedAPI.post('Profile/Update', data)
}

export const getProfileByUsername = async (UserName) => {
  return await store.state.feedAPI.get(`Profile/Username/${UserName}`)
}