<template>
  <div
    class="fixed lg:hidden bottom-2 left-2 right-2 px-6 pt-4 pb-2 flex justify-around items-end rounded-full shadow-lg bg-beige">
    <div class="flex flex-col items-center space-y-1" v-for="(tab, i) in tabs" :key="i" @click=" this.$router.replace(tab.link)"  :class="{ 'text-primary': this.$route.path === tab.link }">
      <vue-feather :type="tab.icon" class="w-6 mx-auto" />
      <p class="text-xs font-poppins font-semibold">{{ tab.name }}</p>
    </div>
  </div>
  <div class="h-24"></div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    active: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      tabs: [
        { name: "Feed", link: '/', icon: 'home' },
        { name: "Search", link: '/search', icon: 'search' },
        { name: "Profile", link: '/profile', icon: 'user' },
      ],
      activeTab: this.active,
    }
  },
};
</script>
