export const serializeClasses = (styles, condition) => {
  return styles
    .filter((s) => condition(s))
    .map((_) => _.classes)
    .join(" ");
};

export const getTutorSessions = tutor => {
  let sessions = []
  tutor.courses.forEach(course => {
    sessions = course.sessions.concat(sessions)
  })
  return sessions
}

export const addUniquely = (array, data, property) => {
  let index = -1;

  for (let i = 0; i < array.length; i++)
    if (array[i][property] === data[property]) index = i;

  if (index > -1) array[index] = data;
  else array.push(data);

  return array;
};

export const createArray = (
  N,
  F = (num) => {
    return num;
  }
) => {
  return [...Array(N).keys()].map((i) => F(i));
};

export class api {
  constructor(base_url, options = {}) {
    this.base_url = base_url;
    this.options = options;
  }

  async request(method, url, options) {
    let opts = {
      method: method,
      headers: {
        "Content-Type":
          options.contentType ||
          this.options.contentType ||
          "application/x-www-form-urlencoded; charset=UTF-8",
        ...this.options.headers,
      },
      ...options,
    };

    if (opts.body) opts.body = JSON.stringify(opts.body);

    try {
      let res = await fetch(this.base_url + url, opts);
      if (res.status >= 200 && res.status < 300) return await res.json();
      else return { success: false, ...res };
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async get(url = "", options = {}) {
    return this.request("GET", url, options);
  }

  async post(url = "", body = {}, options = {}) {
    options.body = body;
    return this.request("POST", url, options);
  }
}

export const is = (pair) => {
  return pair ? pair[0] == pair[1] : true;
};

export const resolve = (theme) => {
  return theme
    .filter((s) => is(s.on))
    .map((_) => _.classes)
    .join(" ");
};

export const stylus = (theme) => {
  let active = theme.filter((s) => s.on);
  return {
    classes: active.map((_) => _.classes).join(" "),
    styles: active.map((_) => _.styles).join(" "),
  };
};

export const checkWindow = (cb, args) => {
  let timeout = -1;
  if (typeof window === "undefined") {
    timeout = setTimeout(() => cb(args), 50);
    return false;
  } else return true;
};

export const getParams = () => {
  if (checkWindow(getParams)) {
    let params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    
    if (typeof params === "undefined" || !params) return {};
    return params;
  }
};

export const readFromLocalStorage = (key, is_json = false) => {
  var value, timeout;

  // prevent setting localStorage on server-side
  if (checkWindow(readFromLocalStorage, [key, is_json]))
    value = localStorage.getItem(key);

  if (is_json && !!value) value = JSON.parse(value);
  return value;
};

export const removeFromLocalStorage = (key) => {
  if (checkWindow(removeFromLocalStorage, [key])) localStorage.removeItem(key);
};

export const saveToLocalStorage = (key, value, is_json = false) => {
  if (!value) return;
  if (is_json) value = JSON.stringify(value);

  // prevent setting localStorage on server-side
  if (checkWindow(saveToLocalStorage, [key, value, is_json]))
    localStorage.setItem(key, value);
};

export const getOffset = (el) => {
  var _x = 0;
  var _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
};

export const slugify = (text) => {
  return text
    .toString()
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-");
};

export const getComponentData = (components, component) => {
  if (components.length == 0 || !Array.isArray(components)) return null;
  let data = components.filter((c) => c.__typename == component);
  return data.length > 1 ? data : data[0];
};

export const timeSince = (timestamp, maxDepth = "seconds") => {
  var date = new Date(timestamp);
  var seconds = Math.floor((new Date() - date) / 1000);
  var interval = Math.floor(seconds / 31536000);
  if (interval > 1 || maxDepth == "years") {
    return interval + " years ago";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1 || maxDepth == "months") {
    return interval + " months ago";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1 || maxDepth == "days") {
    return interval + " days ago";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1 || maxDepth == "hours") {
    return interval + " hours ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1 || maxDepth == "minutes") {
    return interval + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};

export const timeTo = (timestamp, maxDepth = "seconds") => {
  var date = new Date(timestamp);
  var seconds = Math.floor((date - new Date()) / 1000);
  var interval = Math.floor(seconds / 31536000);
  if (interval > 1 || maxDepth == "years") {
    return interval + " years";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1 || maxDepth == "months") {
    return interval + " months";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1 || maxDepth == "days") {
    return interval + " days";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1 || maxDepth == "hours") {
    return interval + " hours";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1 || maxDepth == "minutes") {
    return interval + " minutes";
  }
  return Math.floor(seconds) + " seconds";
};
