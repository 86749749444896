export const PostPopup = {
    namespaced: true,
    state: () => ({
        tags: [],
        text: '',
        active: false,
        isAnonymous: false,
        update: 0,
        editing: false,
    }),
    mutations: {
        activate(state) {
            state.active = true;
            state.update = state.update + 1;
        },
        deactivate(state) {
            state.active = false;
            state.update = state.update + 1;
        },
        addTag(state, tag) {
            state.tags.push(tag);
        },
        removeTag(state, tag) {
            state.tags = state.tags.filter(t => t != tag)
        },
        set(state, payload) {
            for (const [key, value] of Object.entries(payload)) state[key] = value;
        },
        reset(state) {
            state.text = '';
            state.tags = [];
            state.active = false;
            state.isAnonymous = false;
        }
    }
};
