module.exports = {
  time_12: (value) => {
    if (value == 12) return "12pm";
    else if (value > 12) return `${value - 12}pm`;
    else return `${value}am`;
  },
  time_from_date: (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    let minutes = date.getMinutes();
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${hours}:${minutes}`;
  },
  queryToJSON: (query) => {
    query = query.substring(query.indexOf("?") + 1);

    var re = /([^&=]+)=?([^&]*)/g;
    var decodeRE = /\+/g;

    var decode = function (str) {
      return decodeURIComponent(str.replace(decodeRE, " "));
    };

    var params = {},
      e;
    while ((e = re.exec(query))) {
      var k = decode(e[1]),
        v = decode(e[2]);
      if (k.substring(k.length - 2) === "[]") {
        k = k.substring(0, k.length - 2);
        (params[k] || (params[k] = [])).push(v);
      } else params[k] = v;
    }

    var assign = function (obj, keyPath, value) {
      var lastKeyIndex = keyPath.length - 1;
      for (var i = 0; i < lastKeyIndex; ++i) {
        var key = keyPath[i];
        if (!(key in obj)) obj[key] = {};
        obj = obj[key];
      }
      obj[keyPath[lastKeyIndex]] = value;
    };

    for (var prop in params) {
      var structure = prop.split("[");
      if (structure.length > 1) {
        var levels = [];
        structure.forEach(function (item, i) {
          var key = item.replace(/[?[\]\\ ]/g, "");
          levels.push(key);
        });
        assign(params, levels, params[prop]);
        delete params[prop];
      }
    }
    return params;
  },
  calendar_event_from_session: (session) => {
    const { slot, tutor, student, course } = session;

    const attendeeEmail = student.email;
    const organizerEmail = tutor.user.email;
    const summary = `${tutor?.user?.name} - ${course?.class?.name}`;
    const description = `Thank you for booking a session with ${tutor?.user?.name} on ${course?.class?.name}! Please come prepared with questions and join the session at the Google Meet link. If you have any questions, please contact ${tutor?.user?.name} at ${tutor?.user?.email} or Tutoruu support at support@tutoruu.com.`;

    const startTime = new Date(slot.date);
    startTime.setHours(slot.time);
    startTime.setMinutes(0);

    const finishTime = new Date(startTime.getTime());
    finishTime.setHours(slot.time + 1);
    finishTime.setMinutes(0);

    return {
      attendeeEmail,
      organizerEmail,
      summary,
      description,
      startTime: startTime.toISOString(),
      finishTime: finishTime.toISOString(),
    };
  },
  relative_time: (timestamp) => {
    const relativeTime = require("dayjs/plugin/relativeTime");
    const dayjs = require("dayjs");
    dayjs.extend(relativeTime);
    return dayjs().to(timestamp);
  },
  date_month_day: (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getMonth() + 1}/${date.getDate()}`;
  },
};
