import API from "portapi-helper";

const api = new API(process.env.VUE_APP_PUBLIC_FEED_URL);
class feedsAPI {
  constructor(api) {
    this.api = api;
  }
  async request(method, url, body = {}) {
    let opts = {
      method,
      headers: {
        "Content-Type": this.api.options.contentType ?? "application/json",
        ...this.api.options.headers,
      },
    };

    if (method !== "GET") opts["body"] = JSON.stringify(body);

    try {
      let res = await fetch(this.api.base_url + url, opts);
      console.log(this.api.base_url + url, res);
      if (res.status >= 200 && res.status < 300) return await res.json();
      if (res.status === 401 || res.status === 403) {
        return window.location.replace(
          `https://tutoruu.com/account/login?redirect_url=${
            window.location.origin + window.location.pathname
          }`
        );
      }
      return { success: false, ...res };
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async get(URL) {
    return await this.request("GET", URL);
  }
  async post(URL, body) {
    return await this.request("POST", URL, body);
  }
  async patch(URL, body) {
    return await this.request("PATCH", URL, body);
  }
  async delete(URL) {
    return await this.request("DELETE", URL);
  }
}

export const FeedsAPI = new feedsAPI(api);

export * from "./User";
export * from "./Post";
export * from "./Lookups";
