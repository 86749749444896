<template>
  <teleport to="body">
    <transition name="fade" appear>
      <div
        v-if="on"
        class="inset-0 fixed bg-shark bg-opacity-50"
        @click="close()"
      ></div>
    </transition>
    <transition appear name="fade-up">
      <div
        v-if="on"
        class="
          bg-beige
          w-full
          h-auto
          rounded-tr-3xl rounded-tl-3xl
          fixed
          bottom-0
          p-4
          py-7
          flex
          justify-between
          items-center
          z-9999
        "
      >
        <img
          v-if="image"
          :src="image"
          alt=""
          class="w-12 h-12 mr-2 rounded-full"
        />
        <div class="flex-grow">
          <p class="text-lg font-bold py-1">{{ title }}</p>
          <p class="text-base font-extalight whitespace-pre-line">{{ text }}</p>
        </div>
        <Button
          v-if="!!action"
          type="ghost"
          :text="action.label"
          @click="
            () => {
              action.onclick ? action.onclick() : close();
            }
          "
        />
      </div>
    </transition>
  </teleport>
</template>

<script>
import Button from "./button.vue";
export default {
  props: {
    image: String,
    on: Boolean,
    title: String,
    text: String,
    action: Object,
  },
  components: {
    Button,
  },
  emits: ["update:on"],
  methods: {
    close() {
      this.$emit("update:on", false);
    },
  },
};
</script>
