import { createRouter, createWebHistory } from "@ionic/vue-router";
import Tabs from "../views/Tabs.vue";
import store from "../store";
import { getProfile } from "../helpers/API/Feeds";
import { readFromLocalStorage } from "../helpers";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Feeds/Feed.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/discounts",
    name: "Discounts",
    component: () => import("@/views/Feeds/Discounts.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/discounts/:id/use/:user_id",
    name: "Use Discount",
    component: () => import("@/views/Feeds/DiscountUse.vue"),
  },
  {
    path: "/card",
    name: "Card",
    component: () => import("@/views/CardRequested.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/card-info",
    name: "CardInfo",
    component: () => import("@/views/CardInfo.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/pickupLocation",
    name: "PickupLocation",
    component: () => import("@/views/PickupLocation.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/order-confirmation",
    name: "ConfirmCard",
    component: () => import("@/views/ConfirmCard.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/quests",
    name: "Quests",
    component: () => import("@/views/Feeds/QuestsPage.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/appAccepted",
    name: "ApplicationAccepted",
    component: () => import("@/views/Feeds/ApplicationAccepted.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/notification",
    name: "Notification",
    component: () => import("@/views/Feeds/NotificationPage.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/Market",
    name: "Market",
    meta: {
      redirects: "https://booking.tutoruu.com/tutors/",
    },
  },
  {
    path: "/walkThrough",
    name: "WalkThrough",
    component: () => import("@/views/Feeds/WalkThrough.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/sessions",
    name: "Sessions",
    component: () => import("@/views/Feeds/Sessions.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/brandambassador",
    name: "BrandAmbassador",
    component: () => import("@/views/Feeds/BrandAmbassador.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/Tutoruu/About",
    name: "About",
    component: () => import("@/views/Feeds/AboutUs.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/Feeds/Profile.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/Feeds/Settings.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/support",
    name: "Support",
    component: () => import("@/views/Feeds/Support.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/profile/Following",
    name: "Following",
    component: () => import("@/views/Feeds/Following.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("@/views/Feeds/MainSearch.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/chat/ruu",
    name: "Ruu Chat",
    component: () => import("@/views/Ruu/Chat.vue"),
    meta: {
      // requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/u/:username",
    name: "User Profile",
    component: () => import("@/views/Feeds/UserProfile.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/search-tags",
    name: "Search Tags",
    component: () => import("@/views/Feeds/TagSearch.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/saved",
    name: "Saved Posts",
    component: () => import("@/views/Feeds/SavedPosts.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/feed/post/:postId",
    name: "postPage",
    component: () => import("@/views/PostPage.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/FeedIntro",
    name: "FeedIntro",
    component: () => import("@/views/IntroducingFeeds.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/feed",
    name: "Feed",
    component: () => import("@/views/Feeds/Feed.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/Omar",
    name: "Omar",
    component: () => import("@/views/Omar.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/feed/settings",
    name: "Feed Settings",
    component: () => import("@/views/Feeds/FeedSettings.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/profile/edit",
    name: "edit profile",
    component: () => import("@/views/Feeds/EditProfile.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/dashboard",
    name: "TutorDashboard",
    component: () => import("@/views/Feeds/TutorDashboard.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/OTP/",
    name: "OTP",
    component: () => import("@/views/OTP.vue"),
  },
  {
    path: "/login/",
    name: "Login",
    meta: {
      redirects: "https://tutoruu.com/account/login",
    },
  },
  {
    path: "/join/",
    name: "Get connected with tutors from your university",
    meta: {
      redirects: "https://tutoruu.com/account/signup",
    },
  },
  {
    path: "/signup/",
    meta: {
      redirects: "https://tutoruu.com/account/signup",
    },
  },
  {
    path: "/reset-password/",
    name: "Reset Password",
    component: () => import("@/views/ResetPassword.vue"),
  },
  {
    path: "/reset-password/sent",
    name: "Reset Password Sent",
    component: () => import("@/views/ResetPasswordSent.vue"),
  },
  {
    path: "/change-password/",
    name: "Change Password",
    component: () => import("@/views/change-password.vue"),
    meta: {
      skipUser: true,
    },
  },
  {
    path: "/apply/",
    name: "Apply to Tutor",
    component: () => import("@/views/BeginApplication.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/class-search/",
    name: "Class Search",
    component: () => import("@/views/Feeds/ClassSearch.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  // {
  //   path: "/sessions",
  //   name: "Sessions",
  //   component: () => import("@/views/Sessions.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     requiresUser: true,
  //   },
  // },
  {
    path: "/sessions/:id",
    name: "Session Confirmation",
    component: () => import("@/views/SessionConfirmation.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/finance/",
    name: "Finance",
    component: () => import("@/views/Finance.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/chats/",
    name: "Chats",
    component: () => import("@/views/Chats.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/help",
    name: "Help",
    component: () => import("@/views/Help.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/help-is-on-the-way",
    name: "Message Submitted",
    component: () => import("@/views/HelpSubmitted.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/promocodes",
    name: "My Promocodes",
    component: () => import("@/views/Promocodes.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/chats/:id",
    name: "Chat",
    component: () => import("@/views/Chat.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/:username/apply",
    component: () => import("@/views/TutorApplication.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/results",
    name: "Results",
    component: () => import("@/views/Results.vue"),
  },
  {
    path: "/pay/fawry",
    name: "Fawry",
    component: () => import("@/views/Fawry.vue"),
  },
  {
    path: "/schedule/",
    name: "Schedule",
    component: () => import("@/views/Schedule.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/tutor/:username",
    name: "TProfile",
    component: () => import("@/views/Feeds/TutorProfile.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/t/:username",
    name: "TutorProfile",
    component: () => import("@/views/TutorProfile.vue"),
  },
  {
    path: "/tutor/:username/book/session_info",
    name: "BookInfo",
    component: () => import("@/views/BookTopic.vue"),
  },
  {
    path: "/tutor/:username/book/class",
    name: "BookClass",
    component: () => import("@/views/BookClass.vue"),
  },
  {
    path: "/tutor/:username/book/time",
    name: "BookSlot",
    component: () => import("@/views/BookSlot.vue"),
  },
  {
    path: "/tutor/:username/book/location",
    name: "BookLocation",
    component: () => import("@/views/BookLocation.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/tutor/:username/pay",
    name: "Pay",
    component: () => import("@/views/Pay.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/classes/:id",
    name: "ClassInfo",
    component: () => import("@/views/ClassInfo.vue"),
  },
  {
    path: "/classes/swap",
    name: "Swap",
    component: () => import("@/views/Swap.vue"),
    meta: {
      requiresAuth: true,
      requiresUser: true,
    },
  },
  {
    path: "/payment",
    name: "Payment",
    component: () => import("@/views/PaymentConfirmation.vue"),
  },
  {
    path: "/students/:id",
    name: "UserProfile",
    component: () => import("@/views/UserProfile.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const setSEO = (pageData) => {
  document.title = pageData.title;
  document.head.innerHTML += `
    <meta name="description" content="${pageData.description}" />
    <meta name="title" content="${pageData.title}" />
    <meta name="image" content="${pageData.image}" />

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://students.tutoruu.com/${pageData.slug}" />
    <meta property="og:title" content="${pageData.title}" />
    <meta property="og:description" content="${pageData.description}" />
    <meta property="og:image" content="${pageData.image}" />

    <meta
      name="robots"
      content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
    />
  `;
};

const setTutor = async (username) => {
  if (!username) return;
  const tutor = await tutoruuAPI.get(`tutor/username/${username}`);
  store.commit("setTutorPreviewed", tutor);
  let title = `Book a tutoring session with ${tutor.user.name}`;
  let description = "";
  if (tutor.courses.length > 1) {
    description += `Teaches `;
    for (let i = 0; i < tutor.courses.length - 1; i++)
      description += `${tutor.courses[i].class.desc}, `;
    description += `& ${tutor.courses[tutor.courses.length - 1].desc}. `;
  }
  if (!!tutor?.user?.bio) description += tutor.user.bio;

  setSEO({ title, description, image: tutor.user.img_url });
};

export function redirectAuthenticated(to) {
  const url = new URL(to);

  const token = readFromLocalStorage("token");
  if (token) url.searchParams.set("token", token);

  return window.location.assign(url);
}

export async function signIn() {
  const { data, success, code } = await getProfile();
  if ((!success && !data) || code >= 400)
    return window.location.replace(
      `https://tutoruu.com/account/login?redirect_url=${
        window.location.origin + window.location.pathname
      }`
    );

  store.commit("setUserProfile", data.user_profile);
  store.commit("setUserPosts", data.user_posts.data);
}

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresUser = to.matched.some((record) => record.meta.requiresUser);
  const skipUser = to.matched.some((record) => record.meta.skipUser);

  const redirects = to.matched.some((record) => record.meta.redirects);
  if (redirects) redirectAuthenticated(to.meta.redirects);

  if (to.name === "TutorProfile") await setTutor(to.params.username);

  if ((requiresAuth || to.query.token) && !store.state.user) {
    store.commit("setAPIToken", to.query.token);
    await signIn();
  }

  if (requiresUser && !store.state.user) await store.dispatch("get_user");

  next();
});
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
