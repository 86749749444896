import store from "../../../store";

export const getPostbyId = async (id) => {
  return await store.state.feedAPI.get(`Post/${id}`);
};
export const createPost = async (post) => {
  return await store.state.feedAPI.post("Post/Create", post);
};
export const getPostsByFollowing = async () => {
  return await store.state.feedAPI.get("Posts/Following");
};
export const GetFeed = async (cursor = "") => {
  return await store.state.feedAPI.get("Posts/Feed" + cursor);
};
export const GetPostsByUsername = async (username) => {
  return await store.state.feedAPI.get(`Profile/Username/${username}`);
};
export const GetPostsBySearch = async (textSearch) => {
  return await store.state.feedAPI.post("Posts/Search", { textSearch: textSearch });
};
export const GetUserPosts = async () => {
  return await store.state.feedAPI.get("Posts/User");
};
export const GetPostsByUniversity = async (id, cursor = "") => {
  return await store.state.feedAPI.get(`Posts/University/${id}` + cursor);
};
export const GetPostsByCategory = async (university_id, category_id, cursor = "") => {
  return await store.state.feedAPI.get(`Posts/Category/${category_id}/${university_id}` + cursor);
};
export const EditPost = async (post) => {
  return await store.state.feedAPI.post("Post/Edit", post);
};
export const DeletePost = async (id) => {
  return await store.state.feedAPI.post("Post/Delete", { post_id: id });
};
export const GetSavedPosts = async () => {
  return await store.state.feedAPI.get("Posts/Saved");
};

export const ReportPost = async (id) => {
  return await store.state.feedAPI.post("Post/Report", { post_id: id });
};
