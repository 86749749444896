<template>
<transition name="fade-down">
  <div class="w-80 my-2 mx-auto bg-opacity-75 text-white font-bold p-4 rounded-2xl rounded-tr-sm shadow-lg" :class="{'bg-red-500': type === 'error', 'text-orange': type === 'confirmation', 'bg-beige': type === 'confirmation', 'bg-orange': type === 'success', 'bg-shark': type === 'message'}" v-if="snackbar">
    {{ text }}
    <button class="float-right font-bold" :class="{ 'text-shark': type === 'error' || type === 'message', 'text-white': type === 'success', 'text-orange': type === 'confirmation' }" @click="destroy()">
      x
    </button>
  </div>

</transition>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
    data() {
        return {
            snackbar: true,
            interval: -1
        }
    },
    props: {
        timeout: {
            type: Number,
            default: 3000
        },
        type: {
          type: String,
          default: 'confirmation'
        },
        text: String,
        id: Number,
    },
    created() {
        this.interval = setInterval(() => this.destroy(), this.timeout);
    },
    methods: {
      ...mapMutations(['destroyToast']),
        destroy(){
          this.destroyToast(this.id);
          this.snackbar = false;
        }
    },
};
</script>