import store from "../../../store";

export const getUniversities = async () => {
  return await store.state.feedAPI.get('Lookups/Universities');
}

export const getCategories = async () => {
  return await store.state.feedAPI.get('Lookups/Categories');
}

export const getTags = async () => {
  return await store.state.feedAPI.get('Lookups/Tags');
}
