import { readFromLocalStorage } from "../../../helpers";
import store from "../../../store";

async function request(method, url, body = {}) {
  const options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + readFromLocalStorage("token"),
    },
    body: JSON.stringify(body),
  };

  try {
    const res = await fetch(url, options);
    if (res.status >= 200 && res.status < 400) return await res.json();
    if (res.status === 401 || res.status === 403) {
      return window.location.replace(
        `https://tutoruu.com/account/login?redirect_url=${window.location.origin + window.location.pathname}`
      );
    }
    return { success: false, ...res };
  } catch (e) {
    console.error(e);
    return e;
  }
}

export const mail = {
  chat: async (sender_image, sender_name, message, reply_link, email) => {
    return await request("post", "https://zaagel.tutoruu.com/api/send/chat/mail", {
      sender_image,
      sender_name,
      message,
      reply_link,
      email,
    });
  },
};
