/* eslint-disable no-console */

import { register } from 'register-service-worker'

// if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
    },
    registered () {
      self.addEventListener('fetch', function(event) {});
    },
    cached () {
    },
    updatefound () {
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
    },
    updated () {
    },
    offline () {
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
// }
