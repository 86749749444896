import { createCalendarEvent, getSession, getCalendarConnectionUrl } from "tutoruu-bridge";
import { readFromLocalStorage } from "../../helpers";
import parser from "../../helpers/parser";

export const sessionStore = {
  namespaced: true,
  state: () => ({
    calendarLink: null,
  }),
  mutations: {
    setLink(state, link) {
      state.calendarLink = link;
    },
  },
  actions: {
    async getCalendarLink({ state, commit }) {
      if (state.calendarLink) return state.calendarLink;
      const gcal = await getCalendarConnectionUrl(
        "redirect_url",
        readFromLocalStorage("token")
      );
      commit("setLink", gcal.redirect_url);
      return gcal.redirect_url;
    },
    async get(_, id) {
      return await getSession(
        /* GraphQL */ `
        student {
          name
          email
        }
        tutor {
          user {
            name
            email
          }
        }
        slot {
          date
          time
          day
        }
        course {
          class {
            desc
            name
          }
        }
      `,
        id
      );
    },
    async createEvent({ dispatch }, id) {
      const session = await dispatch("get", id);
      const { join_url } = await createCalendarEvent(
        "join_url",
        parser.calendar_event_from_session(session),
        readFromLocalStorage("token")
      );
      return join_url;
    },
  },
};
